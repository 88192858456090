const fileUploadModuleName = {
    temple: 0,
    Yatra: 1,
    Ghat: 2,
    EducationalInstitute: 3,
    Hotel: 4,
    Business: 5,
    Culture: 6,
    Restaurant: 7,
    Park: 8,
    ATM: 9,
    Padav: 10,
    WaterTaxi: 14,
    Cruise: 15,
    BoatRiding: 16,
    Heritage: 17,
    GangaAarti: 18,
    Industries: 19,
    Museum: 20,
    Railways: 21,
    ShoppingPlaces: 22,
    Hospital: 23,
    Transport: 24,
    Entertainment: 25,
    NewsUpdate: 26,
    PhotoAlbum: 27,
    PhotoGallery: 28,
    AudioGallery: 29,
    VideoGallery: 30,
    ThreeSixtyDegreeGallery: 31,
    MasterAttractionType: 32
}

export { fileUploadModuleName }